<template>
  <div class="row mt-1">
    <div class="col-12">
      <div class="card mb-1">
        <div class="bg-light-primary rounded-top py-half px-1">
          <div class="psh-header mb-0 d-flex align-items-center px-0">
            <div class="psh-title border-0 mr-0">
              <i class="bi bi-coin text-h1" style="font-size: 2rem;" />
            </div>
            <h4 class="card-title mb-25 text-primary ml-1 flex-grow-1">
              Charges
            </h4>
            <div>
              <strong v-if="reservedItems && reservedItems.length === 1" role="button" class="text-button mx-1" @click="addDefaultCharges">
                Default Charges
              </strong>
              <strong v-if="!isCurrentOrderShipped" role="button" class="text-button mx-1" @click="openAddCharge">
                Add Charges
              </strong>
            </div>
          </div>
        </div>
        <div class="border rounded-bottom">
          <div v-if="charges && charges.length === 0">
            <div class="text-warning p-half m-1 rounded">
              <p-icon name="bi-exclamation-triangle" class="mr-half" />
              Please use the <h6 role="button" class="p-half d-inline" @click="openAddCharge">
              "Add Charges"</h6>text button located in the header section above to search and add a charge.
            </div>
          </div>
          <table v-else width="100%">
            <tr>
              <th>
                Charged Item
              </th>
              <th>
                Charge Name
              </th>
              <th>
                Rate
              </th>
              <th>
                Count
              </th>
              <th>
                Total
              </th>
              <th v-if="!isCurrentOrderShipped">
                Actions
              </th>
            </tr>
            <tr v-for="charge in charges" :key="charge.id">
              <td>
                {{ charge.itemTitle }}
              </td>
              <td class="text-left pl-half">
                {{ charge.title }}
              </td>
              <td width="70">
                {{ formatCurrency(charge.rate) }}
              </td>
              <td width="60">
                {{ charge.count }}
              </td>
              <td width="90">
                {{ formatCurrency(charge.count * charge.rate) }}
              </td>
              <td v-if="!isCurrentOrderShipped" width="120">
                <span class="delete-btn" role="button" @click="removeAddedCharge(charge.id)">Delete</span>
              </td>
            </tr>
          </table>
          <div v-if="showAddChargeItem" class="">
            <div class="card mb-half">
              <div class="card-header pr-half pl-1 py-half bg-light-primary rounded m-half">
                <div class="card-title flex-grow-1">
                  Add Charge
                </div>
                <strong v-if="!isCurrentOrderShipped" role="button" class="text-button mx-1" @click="openCustomCharge">
                  Add Other Charge
                </strong>
                <dx-util-button icon="add" type="success" class="ml-1" @click="addCharge" />
              </div>
              <dx-util-validation-group ref="chargeValidationRef">
                <div v-for="newcharge in newCharges" :key="newcharge.uid" class="px-half">
                  <div class="d-flex align-items-center">
                    <div class="mr-half">
                      <dx-util-select-box v-model="newcharge.itemId" :data-source="toBeChargedItems" label="Charged Item" display-expr="text" value-expr="id" @selectionChanged="updateToBeChargedItemId($event, newcharge.uid)" />
                    </div>
                    <div v-if="newcharge.chargeType === chargeTypeEnum.PREP_MATERIAL.value" class="py-half flex-grow-1">
                      <dx-util-select-box v-model="newcharge.costId"
                        :data-source="prepMaterials"
                        display-expr="title" value-expr="id"
                        :show-clear-button="true"
                        :search-enabled="true" search-mode="contains"
                        :drop-down-options="{ height: 300 }" item-template="item"
                        label="Prep Item" placeholder="Select an item"
                        @selection-changed="onSelectionChanged($event, newcharge.uid)"
                      >
                        <template #item="{data}">
                          <div class="d-flex text-left">
                            <div class="">
                              {{ data.title }}
                            </div>
                            <div class="flex-grow-1" />
                            <div class="">
                              {{ formatCurrency(data.salePrice) }}
                            </div>
                            <div class="text-right d-inline" style="width: 90px;">
                              <div :class="resolveQuantity(data.quantity)">
                                {{ formatNumber(data.quantity) }}
                              </div>
                            </div>
                          </div>
                        </template>
                        <dx-util-validator>
                          <dx-util-required-rule message="Prep Item selection is required" />
                        </dx-util-validator>
                      </dx-util-select-box>
                    </div>
                    <div v-else class="d-flex flex-grow-1">
                      <div class="mr-half flex-grow-1">
                        <dx-util-text-box v-if="newcharge.chargeType === chargeTypeEnum.OTHER.value" v-model="newcharge.title" label="Title" placeholder="Enter title">
                          <dx-util-validator>
                            <dx-util-required-rule message="Title is required" />
                          </dx-util-validator>
                        </dx-util-text-box>
                      </div>
                      <div class="mr-half">
                        <dx-util-number-box v-if="newcharge.chargeType === chargeTypeEnum.OTHER.value" v-model="newcharge.rate" label="Rate" placeholder="Enter rate/sale price">
                          <dx-util-validator>
                            <dx-util-required-rule message="Rate is required" />
                          </dx-util-validator>
                        </dx-util-number-box>
                      </div>
                    </div>
                    <div class="mr-half">
                      <dx-util-number-box v-model="newcharge.count" :min="1" label="Count" placeholder="Count" width="70">
                        <dx-util-validator>
                          <dx-util-required-rule message="Count is required" />
                          <dx-util-range-rule :min="1" message="Count is required field" />
                        </dx-util-validator>
                      </dx-util-number-box>
                    </div>
                    <div class="mx-half">
                      <dx-util-button icon="close" type="danger" @click="removeCharge(newcharge.uid)" />
                    </div>
                  </div>
                </div>
                <div class="d-flex pt-0 mt-1 px-half">
                  <div class="w-50 pr-half">
                    <dx-util-button class="d-block d-inline" type="danger" text="Cancel" @click="closeAddCharge" />
                  </div>
                  <div class="w-50 pl-half">
                    <dx-util-button class="d-block d-inline" type="success" text="Save Charges" @click="saveCharges" />
                  </div>
                </div>
              </dx-util-validation-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { currencyFormatter } from '@core/utils/filter'
import { confirm } from 'devextreme/ui/dialog'
import { v4 as uuidv4 } from 'uuid'
import chargeTypeEnum from '@/components/charge/charge-type.enum.js'
import fbmService from '@/http/requests/fbm/fbmService'

import useFbmState from '../useFbmStates'

export default {
  setup() {
    const {
      prepMaterials, getPrepMaterials, currentOrder, reservedItems, isCurrentOrderShipped,
    } = useFbmState()
    return {
      prepMaterials, getPrepMaterials, currentOrder, reservedItems, isCurrentOrderShipped,
    }
  },
  data() {
    return {
      chargeValidationRef: 'targetGroup1',
      charges: [],
      showAddChargeItem: false,
      newCharges: [],
      selectedToBeChargedId: null,
      chargeTypeEnum,
    }
  },
  computed: {
    chargeValidationGroup() {
      return this.$refs.chargeValidationRef.instance
    },
    toBeChargedItems() {
      const items = []
      items.push({ id: this.currentOrder.id, text: 'Shipment Box' })
      this.reservedItems.forEach(el => {
        items.push({ id: el.fbmOrderItemId, text: el.productTitle })
      })
      return items
    },
  },
  watch: {
    'currentOrder.id': {
      immediate: true,
      deep: true,
      handler() {
        this.getAddedCharges()
        this.showAddChargeItem = false
      },
    },
  },
  async mounted() {
    await this.getPrepMaterials()
  },
  methods: {
    findChargedItemTitle(itemId) {
      const foundItem = this.toBeChargedItems.filter(el => el.id === itemId)[0]
      return foundItem.text
    },
    async getAddedCharges() {
      if (this.currentOrder.id === 0 || this.currentOrder.id === null || this.currentOrder.id === undefined) return
      const response = await fbmService.getAllCharges(this.currentOrder.id)
      this.charges = response
    },
    async removeAddedCharge(id) {
      const confirmResult = confirm(
        'Are you sure you want to delete this charge? You won\'t be able to revert this!',
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          await fbmService.deleteCharge(id)
          await this.getAddedCharges()
        }
      })
    },
    async openAddCharge() {
      if (!this.showAddChargeItem) {
        this.newCharges = []
      }
      this.showAddChargeItem = true
      this.createCharge()
    },
    async addCharge() {
      // validation removed
      this.createCharge()
    },
    openCustomCharge() {
      this.newCharges.push({
        uid: uuidv4(),
        itemId: this.currentOrder.id,
        costId: null,
        title: '',
        chargeType: chargeTypeEnum.OTHER.value,
        rate: 0,
        count: 1,
        notes: '',
      })
    },
    async addDefaultCharges() {
      await fbmService.getDefaultCharges(this.currentOrder.id)
      await this.getAddedCharges()
    },
    removeCharge(uid) {
      this.newCharges = this.newCharges.filter(el => el.uid !== uid)
    },
    async saveCharges(e) {
      const validationResult = e.validationGroup.validate()
      if (validationResult.isValid) {
        await fbmService.createCharges(this.newCharges)
        this.closeAddCharge()
        this.newCharges = []
        await this.getAddedCharges()
        this.chargeValidationGroup.resetValues()
      }
    },
    updateToBeChargedItemId(e, uid) {
      const toBeChargedId = e.selectedItem.id
      this.newCharges = this.newCharges.map(charge => {
        if (charge.uid === uid) {
          return {
            ...charge,
            itemId: toBeChargedId,
          }
        }
        return charge
      })
    },
    createCharge() {
      this.newCharges.push({
        uid: uuidv4(),
        itemId: this.currentOrder.id,
        costId: null,
        title: '',
        chargeType: chargeTypeEnum.PREP_MATERIAL.value,
        rate: 0,
        count: 1,
        notes: '',
      })
    },
    closeAddCharge() {
      this.showAddChargeItem = false
    },
    onSelectionChanged(e, uid) {
      const selectedCharge = e.selectedItem
      this.newCharges.filter(el => el.uid === uid)[0].costId = selectedCharge.id
      this.newCharges.filter(el => el.uid === uid)[0].title = selectedCharge.title
      this.newCharges.filter(el => el.uid === uid)[0].rate = selectedCharge.salePrice
    },
    resolveQuantity(quantity) {
      if (quantity === 0) return 'badge badge-light-danger'
      if (quantity < 50) return 'badge badge-light-warning'
      return 'badge badge-light-success'
    },
    formatNumber(number) {
      if (typeof number !== 'number' || Number.isNaN(number)) {
        return 'N/A'
      }
      return number.toLocaleString('en-US', { maximumFractionDigits: 0 })
    },
    formatCurrency(v) {
      return currencyFormatter(v)
    },
    scrollToPageEnd() {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth', // Add smooth scrolling behavior if desired
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.text-button {
  margin-left: 16px;
  transition: color 0.3s ease;
}
.text-button:hover {
  color: #66bcf6;
}
.delete-btn {
  margin-left: 16px;
  color: #e4388b;
  transition: color 0.3s ease;
}
.delete-btn:hover {
  color: #c56593;
}

th {
  background-color: #215271;
  color: rgb(181, 200, 227);
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 1px;
}

tr {
  font-size: 13px;
}

tr, td {
  padding: 8px;
  padding-bottom: 8px;
  text-align: left;
}

tr:first-child th:first-child {
  text-align: left;
  padding-left: 6px;
}

</style>
